import React from 'react';
import {Helmet} from 'react-helmet';

export default class About extends React.Component {
    componentDidMount() {
        setTimeout(function() { window.scrollTo(0, 0); }, 1500);
    }
    render(){
        return (
            <div>
            <Helmet>
                <title>Samy & Damien – About – Team of creative directors working at Brand Station Paris.</title>
                <meta name="description" content="Team of creative directors working at Brand Station Paris." />
                <meta property="og:title" content="Samy & Damien – About – Team of creative directors working at Brand Station Paris." />
                <meta property="og:image" content="/img/og.jpg" />
            </Helmet>         
            <div id="trans"/>
                <div id="container-content" className="about">
                    <div className="transition"/>
                    <div className="project">
                        <div className='bio'>
                            <p>Hi! We are Samy&nbsp;&&nbsp;Damien, a team of creative directors working at Brand Station Paris. We think that creative work is most meaningful when it becomes useful and generous.<br/>
                            <span><a href='mailto:samyetdamien@gmail.com'>Contact us – samyetdamien@gmail.com</a></span>
                            </p>
                            
                        </div>
                        <div className='clients'>
                            <div className='agency-list'>
                                <h6>Selected Agency</h6>
                                <ul>
                                    <li>Euro RSCG</li>
                                    <li>Leg</li>
                                    <li>Les Gaulois</li>
                                    <li>Havas 360</li>
                                    <li>Native</li>
                                    <li>Havas Paris</li>
                                </ul>
                            </div>
                            <div className='client-list'>
                                <h6>Selected Clients</h6>
                                <ul>
                                    <li>AC/DC</li>
                                    <li>Axa</li>
                                    <li>Banque Populaire</li>
                                    <li>Citroën</li>
                                    <li>Coca-Cola</li>
                                    <li>Daim</li>
                                    <li>Danone</li>
                                    <li>Deezer</li>
                                    <li>Disney</li>
                                    <li>D&G</li>
                                    <li>Ebay</li>
                                    <li>Eurostar</li>
                                    <li>FFF</li>
                                    <li>Google</li>
                                    <li>Havana</li>
                                    <li>Kfc</li>
                                    <li>Kronenbourg</li>
                                    <li>La Poste</li>
                                    <li>Lipton</li>
                                    <li>L’Oréal</li>
                                    <li>Mattel</li>
                                    <li>Mc Donald’s</li>
                                    <li>Metallica</li>
                                    <li>Milka</li>
                                    <li>Mondelez</li>
                                    <li>Moulinex</li>
                                    <li>Nike</li>
                                    <li>Orange</li>
                                    <li>Oreo</li>
                                    <li>Président</li>
                                    <li>Radio France</li>
                                    <li>Rossignol</li>
                                    <li>Sea Shepherd</li>
                                    <li>Shiseido</li>
                                    <li>SFR</li>
                                    <li>Sony Music</li>
                                    <li>Subway</li>
                                    <li>TF1</li>
                                    <li>Unicef</li>
                                    <li>Universal</li>
                                    <li>Uber</li>
                                    <li>Viceland</li>
                                    <li>Warner Bros</li>
                                    <li>Youtube</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}