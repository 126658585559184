import React from 'react';
import { getProjects } from '../helpers/api';
import IndexList from '../components/IndexList'

export default class Work extends React.Component {
    state = { projects: [], }

    componentDidMount(){
        const projects = getProjects();
        this.setState({projects});
        setTimeout(function() { window.scrollTo(0, 0); }, 1500);

    }
    render(){
        const { projects,  } = this.state;
        return (
            <React.Fragment>
                <div id="trans"/>
                <div id="container-content" className="index" >
                <div className="transition"/>
                    <ul className="projects-list">
                        {projects.map((item, i) => {
                            return (<IndexList project={item} key={i}/>)
                        })}
                    </ul>
                    <div className="clr"></div>
                </div>
            </React.Fragment>
        )
    }
}