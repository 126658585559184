import React from 'react';
import {Helmet} from 'react-helmet';

export default class Homepage extends React.Component {
    state = { projects: [], }
    

    componentDidMount(){
        window.setTimeout(() => {
            this.props.history.push('/work');
         }, 2800)
     
    }
    render(){
        return (
            <React.Fragment>
                <div id="trans"/>
            <Helmet>
                <title>Samy & Damien – Team of creative directors working at Brand Station Paris.</title>
                <meta name="description" content="Team of creative directors working at Brand Station Paris." />
                <meta property="og:title" content="Samy & Damien – Team of creative directors working at Brand Station Paris." />
                <meta property="og:image" content="/img/og.jpg" />
            </Helmet>         
                <div id="container-content" className="prehome" >
                <div className="transition"/>
                    <div className="intro">
                        <div className="content-logo">
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                            <div className="intro-logo"><div className="content">Samy & Damien</div></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}