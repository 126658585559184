import React from 'react';
import { getSingleProject } from '../helpers/api';
import ProjectSingle from '../components/ProjectSingle'

export default class Project extends React.Component {
    state = {
        project: {}
    }
    componentDidMount(){
        const { match: { params } } = this.props;
        const project = getSingleProject(params.project)
        this.setState({project}); 
        setTimeout(function() { window.scrollTo(0, 0); }, 1500);
    }
    render(){
        const { project } = this.state;
        return (
            <React.Fragment>
            <div id="trans"/>
            <div id="container-content" className="single-project">
                <div className="transition"/>
                <div className="project">
                    <ProjectSingle project={project}/>
                </div>
            </div>
            </React.Fragment>
        )
    }
}