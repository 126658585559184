import React from 'react';
import { BrowserRouter as Router, NavLink, } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect";
import Homepage from './containers/Homepage';
import Project from './containers/Project';
import About from './containers/About';
import Awards from './containers/Awards';
import Work from './containers/Work';

import Content from './containers/Content';
import Page from './containers/Page';

import './App.css';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-4773370-19');
ReactGA.pageview(window.location.pathname + window.location.search);


const isProjectPageActive = (match, location) => (location.pathname.includes('/project/') || location.pathname === '/work' ? true : false);

function App(props) {

  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <BrowserView>
          <header id="identity" className="desktop">
            <h1><NavLink name="Work" exact to='/work' href='/work' activeClassName='active'>Samy & Damien</NavLink></h1>
          </header>
          <nav className="desktop" id="primary">
            <ul>
              <li><NavLink name="Work" exact to='/work' href='/work' activeClassName='active' isActive={isProjectPageActive}>Work</NavLink></li>
              <li><NavLink name="About" to='/about' href='/about' activeClassName='active'>About</NavLink></li>
              <li><NavLink name="Awards" to='/awards'  href='/awards' activeClassName='active'>Awards</NavLink></li>
            </ul>
          </nav>
        </BrowserView>
        <div id="main">
          <div id="content" className="section">
            <MobileView>
              <header id="identity" className="mobile">
                <h1><a href="/work">Samy & Damien</a></h1>
              </header>
              <nav className="mobile" id="primary">
                <ul>
                <li><NavLink name="Work" exact to='/work' href='/work' activeClassName='active'>Work</NavLink></li>
                  <li><NavLink name="About" to='/about' href='/about' activeClassName='active'>About</NavLink></li>
                  <li><NavLink name="Awards" to='/awards'  href='/awards' activeClassName='active'>Awards</NavLink></li>
                </ul>
              </nav>
            </MobileView>
            <Content animationClassName="page-slide" animationTimeout={3500} >
              <Page exact path='/' component={Homepage} />
              <Page path='/work' component={Work} />
              <Page path='/about' component={About} />
              <Page path='/awards' component={Awards} />
              <Page path='/project/:project' component={Project} />
            </Content>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
