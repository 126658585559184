import React from 'react';
import { NavLink, } from 'react-router-dom';
import { BrowserView } from "react-device-detect";
import {Helmet} from 'react-helmet';


/*const limitSpeed = (speed, limit) => {
  if(Math.sign(speed) < 0) {
    return Math.max(speed, -1 * limit)
  }
  return Math.min(speed, limit)
};*/

export default class IndexList extends React.Component {

  state = { isOver: false, imgWidth: 0, imgHeight: 0, isMouseMoving: false, MouseX: 0, MouseY: 0 }

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.imgRef = React.createRef();
    this.containerRef = React.createRef();
    this.titleRef = React.createRef();
    this.clientRef = React.createRef();
    this.glitchRef = React.createRef();

  }

  componentDidMount() {
    this.currentPixel = window.pageYOffset;
    this.containerRef.current.style.opacity = 0;
    /*this.looper();*/
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.looper)
  }

  mouseOver = () => {
    this.titleRef.current.classList.toggle('show');
    this.clientRef.current.classList.toggle('show');
    this.glitchRef.current.classList.toggle('onmove');
    this.containerRef.current.style.opacity = 1;
  }

  /*looper = () => {
    const newPixel = window.scrollY;
    const diff = newPixel - this.currentPixel;
    const speed = limitSpeed(diff * 0.15, 50);
    this.currentPixel = newPixel;
    if(this.ref.current) {
      this.ref.current.style.transform = `skewY(${speed}deg)`;
      this.clientRef.current.style.transform = `skewY(${speed}deg)`;
    }
    requestAnimationFrame(this.looper)
  };*/

  mouseOut = () => {
    this.titleRef.current.classList.toggle('show');
    this.clientRef.current.classList.toggle('show');
    this.glitchRef.current.classList.toggle('onmove');
    this.containerRef.current.style.opacity = 0;
  }


  handleMouseMove = event => {
    const imgWidth = this.imgRef.current.offsetWidth;
    const imgHeight = this.imgRef.current.offsetHeight;
    const MouseX = event.pageX;
    const MouseY = event.pageY;
    this.containerRef.current.style.top = `${MouseY - imgHeight/2}px`;
    this.containerRef.current.style.left = `${MouseX - imgWidth/2}px`;
  }

  render() {
    const { project } = this.props;

    return (
      <React.Fragment>
      <Helmet>
          <title>Samy & Damien – Team of creative directors working at Brand Station Paris.</title>
          <meta name="description" content="Team of creative directors working at Brand Station Paris." />
          <meta property="og:title" content="Samy & Damien – Team of creative directors working at Brand Station Paris." />
          <meta property="og:image" content="/img/og.jpg" />
      </Helmet>      
        <li className="animate">
          <NavLink ref={this.ref} to={'/project/'+project.pathUrl} href={'/project/'+project.pathUrl} id={project.id} data-text="project.client" className='link'
                   onMouseOut={this.mouseOut}
                   onMouseMove={this.handleMouseMove}
                   onMouseOver={this.mouseOver}>
            {project.client}
          </NavLink>
          <span ref={this.titleRef} className=''>{project.title}</span>
          <h5 ref={this.clientRef} className='highlight'>{project.client}</h5>
          <div className="clr"></div>
          <div ref={this.containerRef} className="visual-container">
            <img ref={this.imgRef} src={project.imagePreview}  style={{ height: ''}} alt='' />
            <div ref={this.glitchRef} className={'glitch effect'}>
                <React.Fragment>
                <BrowserView>
                <figure className="single videostory">
                  <video preload="" loop muted autoPlay data-poster={project.videoPreview.image} poster={project.videoPreview.image} className='visual-effect'>
                    <source type='video/mp4' src={project.videoPreview.url} />
                  </video>
                </figure>
                </BrowserView>
              </React.Fragment>
            </div>
          </div>
        </li>
      </React.Fragment>
    )
  }
}
