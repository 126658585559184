import React from 'react';
import {Helmet} from 'react-helmet';

export default class Awards extends React.Component {
    componentDidMount() {
        setTimeout(function() { window.scrollTo(0, 0); }, 1500);
    }

    render(){

        return (
            <div>
            <Helmet>
                <title>Samy & Damien – Awards – Team of creative directors working at Brand Station Paris.</title>
                <meta name="description" content="Team of creative directors working at Brand Station Paris." />
                <meta property="og:title" content="Samy & Damien – Awards – Team of creative directors working at Brand Station Paris." />
                <meta property="og:image" content="/img/og.jpg" />
            </Helmet>         
            <div id="trans"/>
                <div id="container-content" className="awards">
                    <div id="trans"/>
                    <div className="transition"/>
                    <div className="project">
                        <ul className="awards-list">
                            <li className="awards-count">Awards</li>
                            <ul className="award-item">
                                <li className="project-title"><span>2020</span>Poulehouse</li>
                                <li>Festival international du film d’animation d’Annecy – Official selection</li>
                                <li>New York Festival – Shortlist - Film craft Animation</li>
                                <li>One Show – Shortlist - Animation</li>
                                <li>One Show – Shortlist - Visual Craft – Animation</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2019</span>APF</li>
                                <li>D&AD Impact – Graphite Pencil – Equality and diversity</li>
                                <li>Eurobest – Bronze – Media</li>
                                <li>Eurobest – Bronze – PR</li>
                                <li>Cannes Lions – Shortlist – PR</li>
                                <li>Cannes Lions – Shortlist – Media</li>
                                <li>Cannes Lions – Shortlist – Outdoor</li>
                                <li>Epica – Silver – Public Interest/Social</li>
                                <li>Lovie Awards – Bronze – Public service & Activism</li>
                                <li>LIA – Shortlist – Public service & Activism</li>
                                <li>Club des DA – Shortlist – Grandes causes</li>
                                <li>Cristal – Shortlist – Promo & Activation Best goodvertising campaign</li>
                                <li>Cristal – Shortlist – Promo & Activation Best use of guerilla marketing</li>
                                <li>Grand Prix Stratégies de la com d’engagement – Bronze</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2019</span>Sea Shepherd</li>
                                <li>Epica – Bronze – Print craft</li>
                                <li>Epica – Bronze – Public interest – Environment</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2019</span>Aquaman</li>
                                <li>D&AD – Shortlist – Outdoor</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2019</span>Hit d'Or CBNews</li>
                                <li>4th best Art director & Copywriter</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2018</span>Hit d'Or CBNews</li>
                                <li>2nd best Art director & Copywriter</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2018</span>L214</li>
                                <li>Epica – Shortlist – Public Interest/Environnement</li>
                                <li>Epica – Shortlist – Public Interest /Environnement</li>
                                <li>Cristal – Shortlist – Print Goodvertising</li>
                                <li>Cristal – Shortlist – Print Good</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2018</span>No Price On Kids</li>
                                <li>Epica – Bronze – Mobile Campaigns</li>
                                <li>Lovie Awards – Gold – Public Service & Activism</li>
                                <li>Lovie Awards – Gold – Jury Price</li>
                                <li>Cristal – Shortlist – Promo & Activation Best goodvertising campaign</li>
                                <li>Cristal – Shortlist – Digital Goodvertising</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2017</span>Metallica The Gloves</li>
                                <li>Cannes Lions – Shortlist – Fans, Social & Digital</li>
                                <li>Cristal Festival – Bronze – Digital/ Viral Film</li>
                                <li>Cristal Festival – Shortlist – Digital/Culture & Entertainment</li>
                                <li>Film & Companies La Baule – Prix – Viral Film</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2017</span>Cadum Reveil Tout Doux</li>
                                <li>Grand Prix du Brand Content – Bronze – Beauté</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2014</span>Florette Fraîcheur et Fantaisie</li>
                                <li>Top/COM – Gold – Affichage</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2013</span>Zepros</li>
                                <li>Grand Prix Presse Magazine – Sélection – Communication Media</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2011</span>Marchons Ensemble</li>
                                <li>Club des DA – Sélection – Etudiant</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2019</span>Lürzer's Archive Ranking</li>
                                <li>Top 100 International – Art director 29 – Copywriter 49</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2019</span>Lürzer's Archive Ranking</li>
                                <li>France – 1st Art director – 1st Copywriter</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2018</span>Lürzer's Archive Ranking</li>
                                <li>Top 100 international – Art director 69</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2019</span>Lürzer's Archive</li>
                                <li>Gestes Propres – Once upon a time</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2019</span>Lürzer's Archive</li>
                                <li>AC/DC – 40 candles</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2019</span>Lürzer's Archive</li>
                                <li>Warner Bros – Aquaman</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2018</span>Lürzer's Archive</li>
                                <li>L214 – 7900 Litres</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2017</span>Lürzer's Archive</li>
                                <li>Metallica – The Gloves</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2016</span>Lürzer's Archive</li>
                                <li>Florette – Fraîcheur et fantaisie</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2015</span>Lürzer's Archive</li>
                                <li>European night of museums</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2014</span>Lürzer's Archive</li>
                                <li>Facom – No Battery-No Glory</li>
                            </ul>
                            <div className="clr"/>
                            <ul className="award-item">
                                <li className="project-title"><span>2014</span>Lürzer's Archive</li>
                                <li>Zepros – L’info pro qui réussit à tous</li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}