const projects = [

    {
    title: 'The egg which doesn’t kill the chicken',
    client: "Poulehouse",
    year: '2019',
    pathUrl:'poulehouse',
    imagePreview: '/img/poulehouse.jpg',
    videoPreview: {
    url: '/videos/poulehouse.mp4',
    vimeo: '',
    youtube: '',
    image: '/img/poulehouse.jpg',
    },
    medias: [
        {
            type: 'video',
            data: {
            url: '',
            vimeo: '400288509',
            youtube: '',
            image: '/img/poulehouse.jpg',
            }
        },
        ],
    credits: [
        {
            data: {
                title: 'Production',
                name: 'Wizz',
            },
        },
        {
            data: {
                title: 'Director',
                name: 'Akama',
            },
        },
        {
            data: {
                title: 'Post Production',
                name: 'Nki',
            },         
        },
        {
            data: {
                title: 'Sound Production',
                name: 'The',
            },         
        },
        ],
    awards: [
        {
            data: {
                title: 'Festival international du film d’animation d’Annecy',
                price: 'Official selection',
            },
        },
        {
            data: {
                title: 'New York Festival',
                price: 'Shortlist - Film craft Animation',
            },
        },
        {
            data: {
                title: 'One Show',
                price: 'Shortlist - Animation',
            },
        },
        {
            data: {
                title: 'One Show',
                price: 'Shortlist - Visual Craft – Animation',
            },
        },
        ],
    },

    {
    title: '#FranceAccessible',
    client: "France Handicap",
    year: '2019',
    pathUrl:'apf-france-handicap',
    imagePreview: '/img/apf.jpg',
    videoPreview: {
    url: '/videos/apf.mp4',
    vimeo: '',
    youtube: '',
    image: '/img/apf.jpg',
    },
    medias: [
        {
            type: 'video',
            data: {
            url: '',
            vimeo: '338903972',
            youtube: '',
            image: '/img/apf.jpg',
            }
        },
        ],
    credits: [
        {
            data: {
                title: 'Production',
                name: 'The Cabs',
            },
        },
    ],
    awards: [
        {
            data: {
                title: 'D&AD Impact',
                price: 'Graphite Pencil – Equality and diversity',
            },
        },
        {
            data: {
                title: 'Eurobest',
                price: 'Bronze – Media',
            },
        },
        {
            data: {
                title: 'Eurobest',
                price: 'Bronze – PR',
            },
        },
        {
            data: {
                title: 'Cannes Lions',
                price: 'Shortlist – PR',
            },
        },
        {
            data: {
                title: 'Cannes Lions',
                price: 'Shortlist – Media',
            },
        },
        {
            data: {
                title: 'Cannes Lions',
                price: 'Shortlist – Indoor',
            },
        },
        {
            data: {
                title: 'Epica',
                price: 'Silver – Public Interest/Social',
            },
        },
        {
            data: {
                title: 'Lovie Awards',
                price: 'Bronze – Public service & Activism',
            },
        },
        {
            data: {
                title: 'LIA',
                price: 'Shortlist – Public service & Activism',
            },
        },
        {
            data: {
                title: 'Club des DA',
                price: 'Shortlist – Grandes causes',
            },
        },
        {
            data: {
                title: 'Cristal',
                price: 'Promo & Activation Best goodvertising campaign',
            },
        },
        {
            data: {
                title: 'Cristal',
                price: 'Shortlist – Promo & Activation Best use of guerilla marketing',
            },
        },
        {
            data: {
                title: 'Grand Prix Stratégies de la com d’engagement',
                price: 'Bronze',
            },
        },
    ],
    },

    {
    title: 'The Metallica Gloves',
    client: "Metallica",
    year: '2019',
    pathUrl:'metallica',
    imagePreview: '/img/metallica-01.jpg',
    videoPreview: {
    url: '/videos/metallica.mp4',
    vimeo: '',
    youtube: '',
    image: '/img/metallica-01.jpg',
    },
    medias: [
        {
            type: 'video',
            data: {
            url: '',
            vimeo: '299406572',
            youtube: '',
            image: '/img/metallica-01.jpg',
            }
        },
        {
            type: 'video',
            data: {
            url: '',
            vimeo: '298586181',
            youtube: '',
            image: '/img/metallica-02.jpg',
            }
        },
    ],
    credits: [
        {
            data: {
                title: 'Production',
                name: 'Ucorp',
            },
        },
        {
            data: {
                title: 'Director',
                name: 'Nicolas Filali',
            },
        },
    ],
    awards: [
        {
            data: {
                title: 'Cannes Lions',
                price: 'Shortlist – Fans, Social & Digital',
            },
        },
        {
            data: {
                title: 'Cristal Festival',
                price: 'Bronze – Digital / Viral Film',
            },
        },
        {
            data: {
                title: 'Cristal Festival',
                price: 'Shortlist – Digital / Culture & Entertainment',
            },
        },
        {
            data: {
                title: 'Film & Companies La Baule',
                price: 'Prix – Viral Film',
            },
        },
    ],
    },

    {
    title: 'The Underwater Poster',
    client: "Warner Bros",
    year: '2019',
    pathUrl:'aquaman',
    imagePreview: '/img/aquaman-preview.jpg',
    videoPreview: {
    url: '/videos/aquaman.mp4',
    vimeo: '',
    youtube: '',
    image: '/img/aquaman-preview.jpg',
    },
    medias: [
        {
            type: 'video',
            data: {
            url: '',
            vimeo: '385495441',
            youtube: '',
            image: '/img/aquaman-preview.jpg',
            }
        },
        {
            type: 'grid',
            data: {
            size: '2',
            url1: '/img/aquaman-01.jpg',
            url2: '/img/aquaman-02.jpg',
            url3: '/img/aquaman-03.jpg',
            }
        },
        ],
        credits: [
            {
                data: {
                    title: 'Creative Direction',
                    name: 'Samy & Damien',
                },
            },
            {
                data: {
                    title: 'Art Direction',
                    name: 'Hugo Venutier',
                },
            },
            {
                data: {
                    title: 'CR',
                    name: 'Elise Bussac',
                },
            },
            {
                data: {
                    title: 'Event Agency',
                    name: 'L\'Alternative',
                },
            },
            {
                data: {
                    title: 'Production',
                    name: 'Kuest',
                },
            },
            {
                data: {
                    title: 'Printing House',
                    name: 'Metropole',
                },
            },
        ],
        awards: [
            {
                data: {
                    title: 'D&AD',
                    price: 'Shortlist – Outdoor',
                },
            },
        ],
    },

    {
        title: 'The Sub Gold 30',
        client: "Subway",
        year: '2019',
        imagePreview: '/img/subway.jpg',
        pathUrl:'subway',
        videoPreview: {
            url: '/videos/subway.mp4',
            vimeo: '',
            youtube: '',
            image: '/img/subway.jpg',
        },
        medias: [
            {
            type: 'video',
            data: {
                url: '',
                vimeo: '298175810',
                youtube: '',
                image: '/img/subway.jpg',
            }
            },
        ],
        credits: [
            {
                data: {
                    title: 'Production',
                    name: 'The Cabs',
                },
            },
            {
                data: {
                    title: 'Sound Production',
                    name: 'CaleSon',
                },
            },
        ],
        awards: [
        ],
    },

    {
    title: '40 Candles',
    client: "AC/DC",
    year: '2019',
    pathUrl:'acdc',
    imagePreview: '/img/acdc-01.jpg',
    videoPreview: {
        url: '/videos/acdc.mp4',
        image: '/img/acdc-01.jpg',
    },
    medias: [
        {
        type: 'video',
        data: {
            url: '',
            vimeo: '357785213',
            youtube: '',
            image: '/img/acdc-01.jpg',
            }
        },
        {
        type: 'video',
        data: {
            url: '',
            vimeo: '400296231',
            youtube: '',
            image: '/img/acdc-02.jpg',
            }
        },
    ],
    credits: [
        {
            data: {
                title: 'Production',
                name: 'HK Corp',
            },
        },
        {
            data: {
                title: 'Sound Production',
                name: 'CaleSon',
            },
        },
    ],
    awards: [
    ],
    },

    {
        title: 'The Recycled Tale',
        client: "Gestes Propres",
        year: '2020',
        pathUrl:'gestes-propres',
        imagePreview: '/img/the-recycled-tale-h.jpg',
        videoPreview: {
            url: '/videos/the-recycled-tale.mp4',
            image: '/img/the-recycled-tale-h.jpg',
        },
        medias: [
            {
            type: 'video',
            data: {
                url: '',
                vimeo: '409898583',
                youtube: '',
                image: '/img/the-recycled-tale-h.jpg',
                }
            },
            {
                type: 'image',
                data: '/img/the-recycled-tale-01.jpg'
            },
            {
                type: 'image',
                data: '/img/the-recycled-tale-02.jpg'
            },
            {
                type: 'image',
                data: '/img/the-recycled-tale-03.jpg'
            },
        ],
        credits: [
            {
                data: {
                    title: 'Illustration',
                    name: 'Tofdru',
                },
            },
            {
                data: {
                    title: 'DOP',
                    name: 'Axel Delwarte',
                },
            },
        ],
        awards: [
        ],
        },

    {
    title: '#NoPriceOnKids',
    client: "Unicef",
    year: '2018',
    pathUrl:'unicef',
    imagePreview: '/img/unicef.jpg',
    videoPreview: {
    url: '/videos/unicef.mp4',
    vimeo: '',
    youtube: '',
    image: '/img/unicef.jpg',
    },
    medias: [
        {
            type: 'video',
            data: {
            url: '',
            vimeo: '298220597',
            youtube: '',
            image: '/img/unicef.jpg',
            }
        },
        ],
        credits: [
            {
                data: {
                    title: 'Interactive Design & Code',
                    name: 'Noël Dupont',
                },
            },
        ],
        awards: [
            {
                data: {
                    title: 'Epica',
                    price: 'Bronze – Mobile Campaigns',
                },
            },
            {
                data: {
                    title: 'Lovie Awards',
                    price: 'Gold – Public Service & Activism',
                },
            },
            {
                data: {
                    title: 'Lovie Awards',
                    price: 'Gold – Jury Price',
                },
            },
            {
                data: {
                    title: 'Cristal',
                    price: 'Shortlist – Promo & Activation Best goodvertising campaign',
                },
            },
            {
                data: {
                    title: 'Cristal',
                    price: 'Shortlist – Digital Goodvertising',
                },
            },
        ],
    },

    {
    title: 'In The Dark',
    client: "Le Lynx",
    year: '2018',
    pathUrl:'le-lynx',
    imagePreview: '/img/lelynx-01.jpg',
    videoPreview: {
    url: '/videos/lelynx.mp4',
    vimeo: '',
    youtube: '',
    image: '/img/lelynx-01.jpg',
    },
    medias: [
        {
            type: 'video',
            data: {
            url: '',
            vimeo: '298217521',
            youtube: '',
            image: '/img/lelynx-01.jpg',
            }
        },
        {
            type: 'video',
            data: {
            url: '',
            vimeo: '298217550',
            youtube: '',
            image: '/img/lelynx-02.jpg',
            }
        },
        {
            type: 'video',
            data: {
            url: '',
            vimeo: '298217594',
            youtube: '',
            image: '/img/lelynx-03.jpg',
            }
        },
        ],
        credits: [
            {
                data: {
                    title: 'Production',
                    name: 'Iconoclast',
                },
            },
            {
                data: {
                    title: 'Director',
                    name: 'Adrien Armanet',
                },
            },
            {
                data: {
                    title: 'Sound Production',
                    name: 'The',
                },
            },
        ],
        awards: [
            
        ],
    },

    {
        title: 'The White Tide',
        client: "Sea Shepherd",
        year: '2019',
        pathUrl:'sea-shepherd',
        imagePreview: '/img/seashepherd-h.jpg',
        videoPreview: {
        url: 'videos/sea.mp4',
        image: '',
        },
        medias: [
            {
                type: 'image',
                data: '/img/seashepherd-01.jpg'
            },
            {
                type: 'image',
                data: '/img/seashepherd-02.jpg'
            },
            {
                type: 'image',
                data: '/img/seashepherd-03.jpg'
            },
        ],
        credits: [
            {
                data: {
                    title: 'Production',
                    name: 'Watch Out',
                },
            },
            {
                data: {
                    title: 'Photographer / CGI Artist',
                    name: 'Salamagica',
                },
            },
        ],
        awards: [
            {
                data: {
                    title: 'Epica',
                    price: 'Bronze – Print craft',
                },
            },
            {
                data: {
                    title: 'Epica',
                    price: 'Bronze – Public interest – Environment',
                },
            },
        ],
    },

    {
    title: 'Projects',
    client: "More",
    year: '2012-2019',
    pathUrl:'random',
    imagePreview: '/img/more.jpg',
    videoPreview: {
    url: 'videos/avocado.mp4',
    vimeo: '',
    youtube: '',
    image: '/img/more.jpg',
    },
    medias: [
        {
            type: 'random',
            data: {
            size: '2',
            class: 'random left size60 two-items',
            url1: '/img/florette-01.jpg',
            url2: '/img/florette-02.jpg',
            title: 'Fresh & Fancy',
            client: "Florette",
            year: '',
        
            }
        },
        {
            type: 'randomvideo',
            data: {
            size: '3',
            class: 'random left size70 three-items',
            vimeo1: '409887426',
            image1: '/img/belin-01.jpg',
            vimeo2: '409890147',
            image2: '/img/belin-02.jpg',
            vimeo3: '409896473',
            image3: '/img/belin-03.jpg',
            title: 'Le coach apéro',
            client: "Belin",
            year: '',
        
            }
        },
        {
            type: 'random',
            data: {
            size: '2',
            class: 'random left size80 two-items',
            url1: '/img/l214-01.jpg',
            url2: '/img/l214-02.jpg',
            title: '#7900 Liters',
            client: "L214",
            year: '',
        
            }
        },
        {
            type: 'randomvideo',
            data: {
            size: '1',
            class: 'random left size80 one-item',
            vimeo1: '409878042',
            image1: '/img/flanby-01.jpg',
            title: 'Les Flanbymitations',
            client: "Flanby",
            year: '',
        
            }
        },
        {
            type: 'random',
            data: {
            size: '1',
            class: 'random left size50 one-item',
            url1: '/img/kfc.jpg',
            title: 'Merci les Bleus',
            client: "KFC",
            year: '2018',
        
            }
        },
        {
            type: 'randomvideo',
            data: {
            size: '1',
            class: 'random left size80 one-item',
            vimeo1: '357824421',
            image1: '/img/mccain-01.jpg',
            title: '#CestMcCainQuiRegale',
            client: "McCain",
            year: '',
            }
        },
        {
            type: 'randomvideo',
            data: {
            size: '2',
            class: 'random left size80 two-items',
            vimeo1: '409881692',
            image1: '/img/dop-01.jpg',
            vimeo2: '409884398',
            image2: '/img/dop-02.jpg',
            title: '#Chantpooing',
            client: "DOP",
            year: '',
            }
        },
    ],
    },
  ]


export function getProjects(){
    return [...projects];
};

export function getSingleProject(path){
    return projects.find((item) => item.pathUrl === path);
};

export function getAwards(){

}
