import React from 'react';
import ReactPlayer from 'react-player'
import {Helmet} from "react-helmet";

export default class ProjectSingle extends React.Component {

    render() {
        const { project } = this.props;
        
        return(
            <div>
                <Helmet>   
                    <title>{`Samy & Damien – ${project.client} – ${project.title}`}</title>
                    <meta name="description" content="Team of creative directors working at Brand Station Paris." />
                    <meta property="og:title" content={`Samy & Damien – ${project.client} – ${project.title}`} />
                    <meta property="og:image" content={project.imagePreview} />
                </Helmet>                
                <div className="title">
                    <h3 className="animate">{project.client}</h3>
                    <h2 className="animate">{project.title}</h2>
                </div>
                    {project.medias && project.medias.map((item, i) => {
                        if (item.type === 'video'){
                            return (
                            <div className="video" data-id={item.data.vimeo} key={i}>
                                <ReactPlayer url={`https://vimeo.com/${item.data.vimeo}`} key={i} width='100%' height='100%' playing light={item.data.image} id={item.data.vimeo}/>
                            </div>
                            )
                        }
                        else if (item.type === 'grid'){
                            if (item.data.size === '3'){
                                return (
                                    <div className="grid-img three" >
                                        <div className="img" key={i} ><img src={item.data.url1} key={i} className="animate" alt='' /></div>  
                                        <div className="img" key={i} ><img src={item.data.url2} key={i} className="animate" alt='' /></div>  
                                        <div className="img" key={i} ><img src={item.data.url3} key={i} className="animate" alt='' /></div>  
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="grid-img two" >
                                        <div className="img" key={i} ><img src={item.data.url1} key={i} className="animate" alt='' /></div>  
                                        <div className="img" key={i} ><img src={item.data.url2} key={i} className="animate" alt='' /></div>  
                                    </div>
                                )
                            }
                        }
                        else if (item.type === 'random'){
                                if (item.data.size === '1'){
                                    return (
                                    <div className={item.data.class} >
                                        <div className="img" key={i} ><img src={item.data.url1} key={i} className="animate" alt='' /></div>
                                        <div className="clr" />
                                        <div className="info">
                                            <h3 className="animate">{item.data.client}</h3>
                                            <h2 className="animate">{item.data.title}</h2>
                                        </div>
                                    </div>
                                    
                                    )
                                }
                                if (item.data.size === '2'){
                                    return (
                                    <div className={item.data.class} >
                                        <div className="visual">
                                            <div className="img" key={i} ><img src={item.data.url1} key={i} className="animate" alt='' /></div> 
                                            <div className="img" key={i} ><img src={item.data.url2} key={i} className="animate" alt='' /></div>  
                                        </div>
                                        <div className="clr" />
                                        <div className="info">
                                            <h3 className="animate">{item.data.client}</h3>
                                            <h2 className="animate">{item.data.title}</h2>
                                        </div>
                                    </div>
                                    )
                                }
                                if (item.data.size === '3'){
                                    return (
                                    <div className={item.data.class} >
                                        <div className="img" key={i} ><img src={item.data.url1} key={i} className="animate" alt='' /></div>  
                                        <div className="img" key={i} ><img src={item.data.url2} key={i} className="animate" alt='' /></div>  
                                        <div className="img" key={i} ><img src={item.data.url3} key={i} className="animate" alt='' /></div>  
                                        <div className="clr" />
                                        <div className="info">
                                            <h3 className="animate">{item.data.client}</h3>
                                            <h2 className="animate">{item.data.title}</h2>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className={item.data.class} >
                                        <div className="img" key={i} ><img src={item.data.url1} key={i} className="animate" alt='' /></div>  
                                        <div className="img" key={i} ><img src={item.data.url2} key={i} className="animate" alt='' /></div>  
                                    </div>
                                )
                            }
                        }
                        else if (item.type === 'randomvideo'){
                            if (item.data.size === '1'){
                                return (
                                <div className={item.data.class} >
                                    <div className="visual">  
                                        <div className="video" data-id={item.data.vimeo1} key={i}>
                                            <ReactPlayer url={`https://vimeo.com/${item.data.vimeo1}`} key={i} width='100%' height='100%' playing light={item.data.image1} id={item.data.vimeo1}/>
                                        </div>
                                    </div>

                                    <div className="clr" />
                                    <div className="info">
                                        <h3 className="animate">{item.data.client}</h3>
                                        <h2 className="animate">{item.data.title}</h2>
                                    </div>
                                </div>
                                
                                )
                            }
                            if (item.data.size === '2'){
                                return (
                                <div className={item.data.class} >
                                    <div className="visual">     
                                        <div className="video" data-id={item.data.vimeo1} key={i}>
                                            <ReactPlayer url={`https://vimeo.com/${item.data.vimeo1}`} key={i} width='100%' height='100%' playing light={item.data.image1} id={item.data.vimeo1}/>
                                        </div>
                                        <div className="video" data-id={item.data.vimeo2} key={i}>
                                            <ReactPlayer url={`https://vimeo.com/${item.data.vimeo2}`} key={i} width='100%' height='100%' playing light={item.data.image2} id={item.data.vimeo2}/>
                                        </div> 
                                    </div>
                                    <div className="clr" />
                                    <div className="info">
                                        <h3 className="animate">{item.data.client}</h3>
                                        <h2 className="animate">{item.data.title}</h2>
                                    </div>
                                </div>
                                )
                            }
                            if (item.data.size === '3'){
                                return (
                                <div className={item.data.class} >
                                <div className="visual">     
                                    <div className="video" data-id={item.data.vimeo1} key={i}>
                                        <ReactPlayer url={`https://vimeo.com/${item.data.vimeo1}`} key={i} width='100%' height='100%' playing light={item.data.image1} id={item.data.vimeo1}/>
                                    </div>
                                    <div className="video" data-id={item.data.vimeo2} key={i}>
                                        <ReactPlayer url={`https://vimeo.com/${item.data.vimeo2}`} key={i} width='100%' height='100%' playing light={item.data.image2} id={item.data.vimeo2}/>
                                    </div> 
                                    <div className="video" data-id={item.data.vimeo3} key={i}>
                                        <ReactPlayer url={`https://vimeo.com/${item.data.vimeo3}`} key={i} width='100%' height='100%' playing light={item.data.image3} id={item.data.vimeo3}/>
                                    </div> 
                                </div>
                                    <div className="clr" />
                                    <div className="info">
                                        <h3 className="animate">{item.data.client}</h3>
                                        <h2 className="animate">{item.data.title}</h2>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className={item.data.class} >
                                    <div className="img" key={i} ><img src={item.data.url1} key={i} className="animate" alt='' /></div>  
                                    <div className="img" key={i} ><img src={item.data.url2} key={i} className="animate" alt='' /></div>  
                                </div>
                            )
                        }
                    }
                        else {
                            return (
                                <div className="img" key={i} ><img src={item.data} key={i} className="animate" alt='' /></div>  
                            )  
                        }
                    })}


                    {(() => {
                        if (project.credits && project.credits.length) {
                        return (
                            <div className="credits">
                                <h3 className="animate">Credits</h3>
                                {project.credits && project.credits.map((item, i) => {
                                    return (
                                    <li key={i}><span>{item.data.title}  –  </span> {item.data.name}</li>  
                                    )  
                                    }
                                )}
                            </div>

                        )
                        }
                    })()}


                    {(() => {
                        if (project.awards && project.awards.length) {
                        return (
                            <div className="prix">
                                <h3 className="animate">Awards</h3>
                                {project.awards && project.awards.map((item, i) => {
                                    return (
                                    <li key={i}><span>{item.data.title}  –  </span> {item.data.price}</li>  
                                    )  
                                    }
                                )}
                            </div>

                        )
                        }
                    })()}

                </div>
        ) 
    }
}